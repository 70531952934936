/*navbar css*/

.header{
    padding: 0;
    margin:0;
    width:100%;
}
.bi-arrow-right{
    color: var(--primary);
}
.navigation{
    background-color: #f0ecfe;
    height:4rem ;
    position: fixed;
    top:0;
    z-index: 1000;
    width:100%;
 backdrop-filter: blur(4px);
}
.logo{
    width:5rem ;
    height: 5rem;
 padding: 1rem;
}
.display-logo h3{
    padding: 1rem;
}
.display-logo{
    display: flex;
    background-color: var(--secondary);
    width:16rem;
    margin:0;
    color: white;
    border-top-right-radius: 40px;
}
.no-logo{
    display: flex;
     background-color: var(--secondary);
    width:16rem;
    margin-right:100%;
    color: white;
    border-top-right-radius: 40px;
}
.no-logo h3{
    display: none;
}
.navbtn{
    color:var(--secondary);
    border:none;
    padding: 0;
    font-size:1.5rem;
    padding: 1rem;
  background-color: transparent;

}
.nav-top
{position:fixed;
    top:0;
    width:100%;
   padding-left: 16rem;
   z-index: 1000;
}
.nav-top-margin{
    position:fixed;
    top:0;
    width:100%;
   padding-left: 10rem;
}
.nav-search{
    padding-right: 3rem;
    padding: 1rem;
    align-self: center;
}
.title{
letter-spacing: 0.12rem;
    color: var(--secondary);
    font-size: 1.5rem;
}
.desktop-img{
    background-color: white;
    border-top-left-radius: 40px;
}
/*header mobile css*/
.login-img{
   width:5rem
}
.header-mobile{
    position: fixed;
   
    z-index: 100;
    top:0;
    right:0;
    left:0;
}
.blur-bg{
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.544);

}
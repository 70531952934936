


.nav-bar{
    position: fixed;
    top:4rem;
    bottom:auto;
    color:white;
    background-color: var(--secondary);
   width:16rem;
   min-height: 100vh;
    margin:0;
    padding: 0 0.75rem;
    transition: margin 300ms ease-in-out;
 z-index: 2000;
 box-shadow: 0rem 0.9375rem 1.875rem 0rem rgb(0 0 0 / 2%);
}
.nav-link{
  font-size:0.8rem
}
.onhover{
    padding:1rem;
    gap:1rem;
    border-radius: 20px;
}
.onhover:hover{
background-color: rgba(255, 255, 255, 0.16);
}
.not-display{
   display: none;
 }
 .parent-drop{
  position: relative;
 }
 .drop{
  position:sticky;
  top:0;
  z-index: 200;
 }
 
 /*sidebar css*/
.sidebar{
    position:fixed;
    top:0rem;
    right:0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width:5rem;
    box-shadow: 0 0 50px 0 rgb(0 0 0 / 10%);
    bottom: 0;
    padding: 1rem 0;
  border-top-left-radius: 40px;
   
}
.sidebar-btn{
    display: flex;
    flex-direction: column;
    width:1.5rem;
   gap:1rem;
 justify-content: center;
 align-items: center;
}
.icon-btn{
    align-self: center;
    margin-left: 1rem;
    color: var(--primary);
    background-color:#f0ecfe;
   border-radius: 5px;
   border:none;
   height: fit-content;
   font-size: 1.5rem;
}
.btn-one{
    border:none;
    background-color: transparent;
    color: var(--secondary);
    font-size: 2rem;
    -webkit-animation: spin 2s linear infinite;
    animation : spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
  
 }
 
 
 .btn-two{
    color:white;
    border:none;
    font-size: 2rem;
    border-radius: 0.3125rem;
    box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgb(119 119 119 / 15%);
     background-color: rgb(111, 219, 255);
 }
 
.flag{
    border-radius: 50%;
    width:3rem;
}
 @-webkit-keyframes spin{

    100%{-webkit-transform: rotate(360deg);}
}
@-moz-keyframes spin{
    100%{-webkit-transform: rotate(360deg);}

}
@keyframes spin {
    100%{-webkit-transform: rotate(360deg);}
}
@media (min-width:60em) {
    .not-display{

        display: block;
        margin-right: 100%;
        background-color: var(--secondary);
        position: fixed;
        top:4rem;
        bottom:0;
        color:white;
        padding: 0 1rem;
        width:5rem;
        z-index:2000;
     }
     .not-display a{
        display: none;
     }
     .tt {  position: relative;}


.tt::before,
.tt::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: rgba(0, 0, 0, 0.532);

  position: absolute;
  
  left: 150%;
  transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.tt::before {
  --translate-y: calc(-100% - var(--arrow-size));

  content: attr(data-tooltip);
  color: white;
  padding: .5rem;
  border-radius: .3rem;
  text-align: center;
  width: max-content;
  max-width: 400%;
  background: var(--tooltip-color);
}

.tt:hover::before,
.tt:hover::after {
  --scale: 1;
}

.tt::after {
  --translate-y: calc(-1 * var(--arrow-size));

  content: '';
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

    
}
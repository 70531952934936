/*popup.css*/
.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.303);
    position: fixed;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalBackground .card{
    background-color: white;
  }
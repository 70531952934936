@media (min-width:60em) {
    .container-fluid, .container-sm, .container-xl, .container-xxl {
        padding-top: 1.875rem;
        padding-right: 1.875rem;
        padding-left: 1.875rem;
        width: 100%;
        font-size: 0.875rem;
        line-height: 1.5;
    }
}
.container-fluid, .container-sm, .container-xl, .container-xxl {
    padding-top: 1.875rem;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5;
}
.row{--bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}
/*cardbubble css*/
.bubble {
    position: relative;
    overflow: hidden;
}
.bubble:before{
    position: absolute;
content: "";
margin:0;
padding: 0;
height: 199px;
width: 188px;
background: var(--primary-dark);
opacity: 0.05;
bottom: -2.3rem;
left: -1rem;
border-radius: 50%;
}
.bubble:after {
    position: absolute;
    content: "";
    height: 266px;
    width: 251px;
    background: var(--primary-dark);
    opacity: 0.05;
    top: -5.6rem;
    left: 23.5rem;
    border-radius: 50%;
}
.bubble-down:after {
    content: "";
    position: absolute;
    height: 343px;
    width: 325px;
    background: #FCDDE8;
    border-radius: 50%;
    bottom: -6.5rem;
    right: -43px;
 
}
.z{
    z-index: 2;
}
.z h1{
    color: var(--secondary);
    font-size: 3.4rem;
}
*::after {
    margin: 0;
    padding: 0;
}
.card {
    margin-bottom: 1.875rem;
    background-color: #fff;
    word-wrap: break-word;
    transition: all .5s ease-in-out;
    border: 0rem solid transparent;
    border-radius: 1.25rem;
    box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
    height: calc(100% - 30px);}
    .card-body{
        padding: 1.875rem;
        flex: 1 1 auto;
    }
    .buy-coin{
        margin-left: 0;
        margin-bottom: 1.2rem;
    display: flex;
    }
    .dash-img img {
        width: 200px;
        position: absolute;
        bottom: -16px;
        z-index: 50;
    right: 0rem;
    }
    .img-fluid{
        max-width: 100%;
    height: auto;
    z-index: 100;
    vertical-align: middle;
   }
   .btn-primary {
    border-color: var(--primary);
    background-color: var(--primary);
}
.btn {
    padding: 0.7875rem 1.819rem;
    border-radius: 0.5rem;
    font-size: 14px;
    line-height: 1.5;
    color:white;
}
.btn-primary:hover{
    background-color:var(--primary-hover);
}
.swiper-pointer-events {
    touch-action: pan-y;
}
.swiper {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.card-widget {
    overflow: hidden;
    margin-right: 1rem;
    aspect-ratio: 1;
  
}
.card-widget .card-widget-info {
    padding: 1rem;
    padding-bottom: 0;
  
    flex-shrink: 0;
}
.wrap{
    position: relative;
    z-index: 1;
    display: flex!important;
    transition-property: transform;
}
.chart{
   
   height: 10rem;
   bottom: 0;
   right: 0;
   left: 0;
}
.full-border{
    border: 1px solid black;
}
/* buy-sell-card*/
.buy-sell .nav-tabs {
    justify-content: space-around;
    border-color: var(--rgba-secondary-1);
}
.buy-sell .nav-tabs .nav-link {
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--secondary);
    background: transparent;
    border: 0;
    width: 50%;
    position: relative;
    padding-bottom: 13px;
}
.buy-sell .nav-tabs .nav-link.active {
    color: var(--primary);
}

.bg-black{
    background-color: black;
    color: white;
}
.buy-sell .nav-tabs .nav-link:after {
    content: "";
    height: 4px;
    position: absolute;
    bottom: 0;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary);
    transition: all 0.2s;
}
.buy-sell .nav-tabs .nav-link.active:after {
    width: 100%;
}
.limit-sell .nav-tabs .nav-link.active {
    background: var(--primary);
    color: #fff;
    border-radius: 7px;
}

.limit-sell .nav-tabs .nav-link {
    background: transparent;
    border: 0;
    text-transform: uppercase;
    color: var(--primary);
    padding: 5px 0px;
    width: 47%;
    font-size: 13px;
    font-weight: 500;
}

.limit-sell .nav-tabs {
    background: var(--rgba-primary-1);
    justify-content: space-around;
    border-radius: 0.625rem;
    border-bottom: 0;
    padding: 5px 2px;
}


.limit-sell {
    padding: 0 45px;
    margin-top: 2rem;
}
.sell-element .sell-blance {
    margin-bottom: 0.6rem;
}
.sell-element {
    padding: 0 30px;
}

.text-primary {
    color: var(--primary) !important;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.sell-balance .input-group-text {
    border: 0;
    background-color: transparent;
    font-size: 12px;
    position: absolute;
    height: 100%;
    right: 0;
    padding: 0px 24px;
    color: var(--primary);
}

.bg-overall{
    background-color:#f0ecfe;;
}
.text-16-px{
    font-size: 16px;
    font-weight: 500;
}
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

 .sell-balance .form-control {
    border: 0;
    background-color: var(--rgba-primary-1);
    border-radius: 10px !important;
    padding-right: 60px;
    padding-left: 20px;
    height: 45px;
    font-weight: 500;
    font-size: 18px;
}
.sell-balance .form-label{
    margin-top: 1rem;
}
/*dashTable css*/
.table-responsive {
    background-color: white;
    max-width: 100%;
    border-radius: 25px;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
thead{
    background-color:white;
    color:var(--primary);
    font-size: large;
}
tbody,.extra{
    font-size: smaller;
}
tr:hover{
    background-color:#fdeef3;
}
 thead:hover{
    color:#fff;
}

body{
    background-color: #f0ecfe;
}
/*Dashboard css*/
.dashboard{
    padding: 0;
    margin:0;
    position: relative;
    overflow-x: hidden;
}
.body{
    padding-top: 4rem;
}


.header-mobile{
    display: block;
}
.header-desktop{
    display: none;
}
.margin-active,.margin-non-active{
    margin:0;
}
.dropdown{
    position: relative;
}
.dropdown-links{
    font-size: 0.8rem;
    text-decoration: none;
    margin-left: 0.7rem;
}
.csvlink{
    border-radius: 2px;
    background-color: #afafaf50;
    margin-left: 1rem;
}
@media (min-width:60em) {
    .header-desktop{
        display: block;
    }
    .header-mobile{
        display: none;
    }
    .body{
        padding-top: 4rem;
        padding-left:5rem;
      padding-right: 5rem;
    }
    .margin-active{
        margin-left:11rem;
    }
    .margin-non-active{
        margin:0;
    }
    
}
/*Login*/
.login-wrapper{
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 2rem;
   height:100vh;
    background-color: #f0ecfe;
}


.btn-forth:hover{
background-color:#fff;
}
.btn-forth{
    border:2px solid var(--primary);
   
}
form h1{
    color: var(--secondary);
}

/*signup*/
h1{
    color: var(--secondary);
}
.signUp{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:auto;
    width:100vw;
}
.sign-up{
    background-color: #f0ecfe;
}
.sign-up .signupLink{
display: grid;
align-items: center;
justify-content: center;
}
/*forget-password*/
.forget-password{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:auto;
    background-color: #f0ecfe;
    width:100vw;
    height:100vh;
}
.resetpass{
    color: var(--primary);
}
/*Form*/
.userForm{
    background-color: #f0ecfe;
    color: var(--secondary);
}
label{
    color:var(--primary)
}
.card .title::after{
    content: "";
    height: 3px;
    width: 100%;
    background-color: var(--primary);
    position: absolute;
    left: 0;
    bottom: -13px;

}
 .card .title {
    position: relative;
    margin-bottom: 0;
    font-size: 1rem;
    color: #6e6e6e;
    margin-bottom: 2rem;
}
@media (min-width:60em) {
    .flex-userform{
        display: flex;
    justify-content: space-between;
    width:100%;
    position: relative;
    }
    
    .cards{
        padding-left: 2rem;
        width:30%;
    }
    .useform{
    width:60%;
    margin-right: 2rem;
    }   
}

.userTable{
    background-color: #f0ecfe;
    color: var(--secondary);
}
/*error*/
.authincation {
    background: var(--rgba-primary-1);
    display: flex;
    min-height: 100vh;
  }
  .error-page .error-text {
    font-size: 5rem;
  }
  .error-page .error-text {
    font-size: 9.375rem;
    line-height: 1;
  }
  .form-control{
    border-radius: 0.625rem;
border-color: #eeee;

  }
  .alert-position{
    position: absolute;
    top:0;
    left:50%;
  }

  /*table*/
  /* .rdt_TableCell{
min-width: max-content;
  }
  .bQdYbR {
    min-width: 19rem !important
  } */

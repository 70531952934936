.App {
  margin:0;
  text-align: center;
  overflow-x: hidden;
}
*,*::before,*::after{
  box-sizing: border-box;
}

:root{
   --primary: #9568FF;
    --secondary: #362465;
    --secondary-dark: #22173f;
    --primary-hover: #7135ff;
    --primary-dark: #3d00ce;
    --rgba-primary-1: rgba(149, 104, 255, 0.1);
    --rgba-primary-2: rgba(149, 104, 255, 0.2);
    --rgba-primary-3: rgba(149, 104, 255, 0.3);
    --rgba-primary-4: rgba(149, 104, 255, 0.4);
    --rgba-primary-5: rgba(149, 104, 255, 0.5);
    --rgba-primary-6: rgba(149, 104, 255, 0.6);
    --rgba-primary-7: rgba(149, 104, 255, 0.7);
    --rgba-primary-8: rgba(149, 104, 255, 0.8);
    --rgba-primary-9: rgba(149, 104, 255, 0.9);
    --rgba-secondary-1: rgba(54, 36, 101, 0.1);
    --rgba-secondary-2: rgba(54, 36, 101, 0.2);
    --rgba-secondary-3: rgba(54, 36, 101, 0.3);
    --rgba-secondary-4: rgba(54, 36, 101, 0.4);
    --rgba-secondary-5: rgba(54, 36, 101, 0.5);
    --rgba-secondary-6: rgba(54, 36, 101, 0.6);
    --rgba-secondary-7: rgba(54, 36, 101, 0.7);
    --rgba-secondary-8: rgba(54, 36, 101, 0.8);
    --rgba-secondary-9: rgba(54, 36, 101, 0.9);
    --font-family-base: poppins, sans-serif, sans-serif;
    --font-family-title: poppins, sans-serif, sans-serif;
    --title: #374557;
    --primary2: #B65FBD;
    --primary-hover2: #9e44a5;
    --primary-dark2: #59265d;
    --rgba-primary-1-2: rgba(182, 95, 189, 0.1);
    --rgba-primary-2-2: rgba(182, 95, 189, 0.2);
    --rgba-primary-3-2: rgba(182, 95, 189, 0.3);
    --rgba-primary-4-2: rgba(182, 95, 189, 0.4);
    --rgba-primary-5-2: rgba(182, 95, 189, 0.5);
    --rgba-primary-6-2: rgba(182, 95, 189, 0.6);
    --rgba-primary-7-2: rgba(182, 95, 189, 0.7);
    --rgba-primary-8-2: rgba(182, 95, 189, 0.8);
    --rgba-primary-9-2: rgba(182, 95, 189, 0.9);

}
.App{
  background-color: #f0ecfe;
  width:100vw;
  overflow-x: hidden;
  box-sizing: border-box;
  
}

